import React, {useEffect, useState, useGlobal} from "reactn";
import {Table, Space, Button, Input, Popconfirm,message,Tag} from 'antd';
import ExportJsonExcel from 'js-export-excel';
import moment from 'moment';
import api from '../service/index'
import {Link} from 'react-router-dom';
import qs from 'qs';
import { ImportExcel } from '../components';


import {
    EditOutlined,
    DeleteOutlined,
    PlusOutlined,
    CheckOutlined,
    DownloadOutlined,
    CloseOutlined,
    Loading3QuartersOutlined,
    FileExcelOutlined
} from "@ant-design/icons";

const LiveSupport = (props) => {

    const {Search} = Input;
    let [data, setData] = useState([]);
    let [unchangedData, setUnchangedData] = useState([])
    let [filterMonth, setFilterMonth] = useState([]);
    let [filterYear, setFilterYear] = useState([]);
    let [excelData, setExcelData] = useState([]);
    let [totalCount, setTotalCount] = useState(0)
    let [selectedRows, setSelectedRows] = useState([])
    let [search, setSearch] = useState(false)
    let [loading, setLoading] = useState(false)
    let [modules] = useGlobal("modules");
    let [isSmall] = useGlobal('isSmall');
    let [user,] = useGlobal("user");
    let [languagesCms] = useGlobal("languagesCms"); 

    let path = props.location.pathname.split('/')[1];
    let module
    if (modules !== null) {
        [module] = modules.filter(el => el._id === path);
    }



    let get = async (page, filters, sorter) => {
        if (page) {
            page = {page: page.current, pageSize: page.pageSize}
        } else {
            page = {page: 1, pageSize: 10};
        }
        let shortString = 'order';

        if (sorter) {
            if (Object.keys(sorter).length) {
                shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field
            }
        }

        let _params = {sort: shortString, ...page,};

        if (search.length > 2) {
            _params["search"] = search.toLowerCase();
            _params["searchFields"] = "title";
        }
        let filterMonth=[];
        let filterYear=[];

        let restData = await api.get('/api/liveSupportMessages', {_params}).then(({data}) => {
            console.log("data   ",data)
            if (data.result) {
                setLoading(false)
                setTotalCount(data.result.total);
                console.log("data.row ",data.result)
                return data.result.map((item, key) => {

                //   item.name=item.attendeeName,
                //   item.lastname=item.attendeeLastName,
                //   item.firstMessageDate=item.createdDate,
                //   item.lastMessageDate=item.lastMessageDate,
                //   item.messageRef=item.messageRef
                    item.key = key;
                    return item;
                })
            }
            
        });
        setData(restData);
        setUnchangedData(restData);

    }

    useEffect(() => {
        if (modules) {
            setLoading(true)
            get();
        }
    }, [])

    useEffect(() => {
        get();
    }, [search]);

    let rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows);
        },
        onSelect: (record, selected, selectedRows) => {
            setSelectedRows(selectedRows);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
            setSelectedRows(selectedRows);
        },
    };

console.log("filterMonth    ",filterMonth)
    let onChange = async (e) => {
        setSearch(e.target.value);
        get();
    }
    
  let downloadExcel = async () => {
    var option = {};
    let dataTable = [];

          let obj = {
            // year: "year",
            // month: "month",
            // dsrid: "dsrid",
            // dsrName: "dsrname",
            // totaltargetKg: "totaltargetkg",
          };

          dataTable.push(obj);

    let sheetAreas = [
      'year',
      'month',
      'dstname',
      'dsrid',
      'totaltargetkg'
    ];

    option.fileName = 'targets';
    option.datas = [
      {
        sheetData: dataTable,
        sheetName: 'sheet',
        sheetFilter: sheetAreas,
        sheetHeader: sheetAreas,
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  };
    let uploadExcel = async () => {
        let newData;
    
        let updateCount = 0;
        let insertCount = 0;
    console.log("excel data   ",excelData)
        if (excelData) {
            if(excelData.length>0){
            message.success(
                'İşleminiz Devam Etmektedir. ',
                2,
              );}
          for (let i in excelData) { 
            newData = {
                year: String(excelData[i].year) || '',
                month: String(excelData[i].month) || '',
                dsrName: String(excelData[i].dstname) || '',
                dsrid: String(excelData[i].dsrid) || '',
                totalTargetKg: Number(Math.round(excelData[i].totaltargetkg)) || '',
              };

               await api
                .post('/api/incomingTargets', newData)
                .then(({ data: { result, result_message } }) => {});
              insertCount++;   

            
          }
          if (updateCount || insertCount) {
            message.error(
              'Excel Yüklendi. ' +
                updateCount +
                ' Güncellendi, ' +
                insertCount +
                ' Kayıt Eklendi.',
              2,
            );
          }
        }
      };
      let getReport = async () => {
        //let lang = "TR";

        let result = await api.get(
            `/api/liveSupportMessagesReport`,
        );
        const file = new Blob(['\ufeff', result.data]);
        let _url = window.URL.createObjectURL(file);
        let a = document.createElement('a');
        a.href = _url;
        a.download = `${"messages".replace(/ /g, '')}_report.xls`;
        document.body.appendChild(a);
        a.click();
        a.remove();
    };
    
      useEffect(() => {
        uploadExcel();
      }, [excelData]);
      console.log("user.distributorName     ",data)
      const filter = (e) => {
        const val = e.target.value.toLocaleLowerCase();
        const tempList = Object.assign([], unchangedData);
        if (val === '' || !val) {
           setData(unchangedData)
           return;
        }
        let filteredData = tempList.filter(t => isContainsFilterValue(t, val))
        setData(filteredData)
     }
  
     const isContainsFilterValue = (t, val) => {
        const isContains2 = t.distributorName == null ? false : t.distributorName.toLowerCase().indexOf(val) !== -1;
        return isContains2;
     }
  
     useEffect(() => {
        get();
     }, [])
  

    let columns = [
        {
            title:"Kullanıcı Adı",
            dataIndex:'attendeeName',
            key:'attendeeName',
            sorter:(a,b)=>a.attendeeName-b.attendeeName,
            dortDirections:['descend','ascend'],

        },
        {
      title: "Soyad",
            dataIndex: 'attendeeLastName',
            key: 'attendeeLastName',
            sorter: (a, b) => a.attendeeLastName- b.attendeeLastName,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: "İlk Mesaj Tarihi",
                  dataIndex: 'createdDate',
                  key: 'createdDate',
                  sortDirections: ['descend', 'ascend'],
                  render: (text) => moment(text).format("YYYY-MM-DD HH:mm"),
              },
        {
            title:"Son Mesaj Tarihi",
            dataIndex: 'lastMessageDate',
            key: 'lastMessageDate',
            render: (text) => moment(text).format("YYYY-MM-DD HH:mm"),
            sortDirections: ['descend', 'ascend']
        },
        {
            title: 'İşlemler',
            key: 'action',
            className: 'editColumn',
            width: 150,
            render: (text, record) => (
                <Space size="small">
                                        <Link to={"/liveSupport/list/" + record.messageRef}>
                                        <Button type="primary" ghost icon={<EditOutlined/>}>
                                            {"Mesajları Gör"}
                                        </Button>
                                    </Link>

                    {/* <Popconfirm
                        onConfirm={() => deleteRow(record._id)} title={languagesCms.CHECK_DELETE}
                        okText={languagesCms.OK_TEXT} cancelText={languagesCms.CANCEL_TEXT}>
                        <Button type="danger" icon={<DeleteOutlined/>}>
                            {isSmall ? '' : languagesCms.DELETE}
                        </Button>
                    </Popconfirm> */}
                </Space>
            ),
        },
    ];

    return (
        <div>
            <div className="list-head">
                <div className="list-title">
                    <h1>{module ? module.name : ""}</h1>
                </div>
                <div className="list-buttons"> {/*
                    {!isSmall && (
                        <Button type="danger" icon={<DeleteOutlined/>} size="large" onClick={deleteSelectedRows}
                                style={{marginRight: "5px"}}>{languagesCms.DELETE_SELECTED}</Button>
                    )}*/}
          {!user.distributorSAPCode &&
                        <Button
                            type="primary"
                            size="large"
                            icon={<FileExcelOutlined />}
                            onClick={() => getReport()}
                        >
                            {isSmall ? '' : "Export Excel"}
                        </Button>
                    }
        {/* {!isSmall && !user.distributorSAPCode && 
                  <Button
                  size={'large'}
                  onClick={downloadExcel}
                  style={{ marginRight: '5px' }}
                  icon={<DownloadOutlined />}
                >
                  {!isSmall && 'Export Excel'}
                </Button>} */}
                </div> 
            </div>

            <div className="table-wrap">
            <Search placeholder="Ara" onChange={filter}/>

                <Table dataSource={data} columns={columns}
                       loading={{spinning: loading, indicator: <Loading3QuartersOutlined spin/>, size: "large"}}
                       pagination={{
                           total: totalCount
                       }}
                       rowSelection={{...rowSelection}}/>
            </div>

        </div>
    );
};


export default LiveSupport;
