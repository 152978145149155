import React, { useEffect, useState, useGlobal, useRef, useContext } from "reactn";
import { Form, Input, InputNumber, Popconfirm, Table, Typography,Button ,message} from 'antd';
import { useParams } from 'react-router-dom'
import api from '../service/index'
import moment from 'moment';

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
const LiveSupportDetail = (props) => {
    let params = useParams()
    let messageRef = params.messageRef;
  
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  let [loading, setLoading] = useState(false)
  let [totalCount, setTotalCount] = useState(0)
  let [current, setCurrent] = useState(1);
  const [count, setCount] = useState(1);

  const isEditing = (record) => record.key === editingKey;
  let [modules] = useGlobal("modules");

  let path = props.location.pathname.split('/')[1];
  let module
  if (modules !== null) {
    [module] = modules.filter(el => el._id === path);
  }

  let get = async (page, filters, sorter) => {
    if (page) {
      page = { page: page.current, pageSize: page.pageSize }
    } else {
      page = { page: 1, pageSize: 10 };
    }
    let shortString = 'order';

    if (sorter) {
      if (Object.keys(sorter).length) {
        shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field
      }
    }

    let _params = { sort: shortString, ...page, messageRef: messageRef };


    let restData = await api.get('/api/liveSupportMessages/' + messageRef).then(({ data }) => {
      if (data.result && data.result) {
        console.log("data.result   ",data.result.total)
        setLoading(false)
        setTotalCount(data.result.total);
        setCount(data.result.total)
        setCurrent(data.result.page);

        return data.result.map((item, key) => {
            if (item.isSupportUser)
              item.isSupport = <div className="true-circle"></div>;
            else item.isSupport = <div className="false-circle"></div>;

          item.key = key;
          return item;
        })
      }
    });

    setData(restData);
  }


  useEffect(() => {
    if (modules) {
      setLoading(true)
      get();
    }
  }, [])

  const edit = (record) => {
    console.log("RECORD    ",record)

    form.setFieldsValue({
      message: '',
      sendDate: new Date(),
      ...record,
    });
    setEditingKey(record.key);
  };
  const cancel = () => {
    setEditingKey('');
  };
  const handleAdd = () => {
    const newData = {
      key: count,
      message: '',
      sendDate: new Date(),
    };
    setData([...data, newData]);
    setCount(count + 1);
  };
  const save = async (key) => {
    console.log(key)
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
      console.log("newData    save      ",newData)
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        /*if(item.messageId){
            newData[index].chatId=messageRef
        await api.post("/api/updateLiveSupportMessage", { data: newData[index] }).then(({ data: { result, result_message } }) => {
            if (result_message.type === 'error') {
              message.error(result_message.message, 2);
            } else {
              message.success("Mesajınız Başarılı Olarak Gönderilmiştir.", 2);
              window.location.reload(false);
            }
          })
        }
        else{
            newData[index].chatId=messageRef

            await api.post("/api/answerLiveSupportMessage", { data: newData[index] }).then(({ data: { result, result_message } }) => {
                if (result_message.type === 'error') {
                  message.error(result_message.message, 2);
                } else {
                  message.success("Mesajınız Başarılı Olarak Gönderilmiştir.", 2);
                  window.location.reload(false);
                }
              })      
        }*/
        setData(newData);
        setEditingKey('');

      } else {
        newData.push(row);
        setData(newData);
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };
  const columns = [
    {
      title: 'Mesaj',
      dataIndex: 'message',
      width: '60%',
      editable: true,
    //   render(text, record) {
    //     return {
    //       props: {
    //         style: { "border-color": record.isSupportUser
    //             ==true ? "red" : "green" }
    //       },
    //       children: <div>{text}</div>
    //     };
    //   }
    },
    {
      title: 'Gönderim Tarihi',
      dataIndex: 'sendDate',
      width: '20%',
      render: (text) => moment(text).format("YYYY-MM-DD HH:mm"),

    },
    {
        title:"Admin",
        width: '5px',
        dataIndex: 'isSupport',
        key: 'isSupport',
        sortDirections: ['descend', 'ascend'],
      },
    {
      title: 'İşlemler',
      dataIndex: 'operation',
      width: '20%',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              Kaydet
            </Typography.Link>
            <Popconfirm title="İptal etmek istediğine emin misin?" onConfirm={cancel}>
              <a>İptal</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
            Düzenle
          </Typography.Link>
        );
      },
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'age' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  return (
    <div>
                    <div className="list-head">

                        <div className="list-buttons"> 
                        
    <Button
    onClick={handleAdd}
    type="primary"
    style={{
      marginBottom: 16,
    }}
  >
    Yeni Mesaj
  </Button>
  </div>
  </div>

  <div className="table-wrap">

    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={data}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={{
          onChange: cancel,
        }}
      />
    </Form>
    </div>
    </div>

  );
};
export default LiveSupportDetail;